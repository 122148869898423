import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import FooterContainer from "../components/footercontainer"
import Relatedjirei from "../components/relatedjirei"
import Relatedshikin from "../components/relatedshikin"
import Relatedsogyomae from "../components/relatedsogyomae"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CircleA from "../components/circleA"
import CircleB from "../components/circleB"
import Flower from "../components/flower"
import CircleC from "../components/circleC"
import Uploads from "../components/uploads"

export default ({ data, pageContext }) => (
  <Layout>
    <SEO />
    <main id="topMain">
        <div id="topContentainer">
            <div className="topContent">
                <section className="topContentBox">
                    <div className="topHero">
                        <div className="inner">
                            <h1>これからの時代の資金調達</h1>
                            <h2>～資金調達から始まる事業運営～</h2>
                            <div className="topHeroText">
                                <p>
                                    事業を行う上で、資金調達は最も重要な戦略です。<br className="brGone"/>
                                    どんな良いアイディアであっても資金調達ができなければ実行することはできません。<br className="brGone"/>
                                    また資金調達後も事業計画の実行や資金繰り管理など、調達後にやるべきことも多いです。<br /><br />
                                    
                                    これからの資金調達は目的に合わせて、手段を変えることが大事です。<br className="brGone"/>
                                    同じ融資であってもどこから借りるのか、どの順序で借りるのかで結果は変わってきます。<br /><br />
                                    
                                    しっかりと打ち合わせをすることであなたに合った最適な資金調達を実現しましょう。
                                </p>
                            </div>
                            <div className="button"><Link to={`/service/`}>資金調達について詳しく</Link></div>
                        </div>
                    </div>
                    <CircleA />
                    <CircleB />
                    <Flower />
                </section>
                <section className="topUploads"> 
                  <div className="topUploadsBox">
                    <div className="inner">
                    <Uploads />
                      <Slider dots={true} infinite={true} speed={500} autoplay={true} autoplaySpeed={4000} arrows={false} slidesToScroll={1} slidesToShow={1} >
                      {data.allContentfulBlogPost.edges.map(({ node }) => (
                                <Link to={`/${node.slug}/`}>
                                  <div className="topUploadsList" key={node.id}>
                                    <h3>{node.title}</h3>
                                    <time datatime={node.publishDate}>
                                        {node.publishDateJP}
                                    </time>
                                  </div>
                                </Link>
                            ))}
                      </Slider>
                    </div>
                    <CircleC />
                  </div>
                </section>
                <section className="topAbout">
                    <div className="inner">
                        <div className="topAboutContent">
                            <div className="daihyo"><img src="/images/daihyou-kouno.svg" alt="代表" /></div>
                            <div className="topAboutText">
                                <p>
                                    税理士の甲能知樹です。<br />
                                    融資などこれからの資金調達について前向きに発信しています！
                                    これまでにたくさんの融資支援の経験をしてきました。事業計画以前の事前準備から情報共有する必要を感じ、サイトの運営を行っています。
                                </p>
                                <div>甲能税理士事務所</div>
                            </div>
                        </div>
                        <div className="aboutButton"><Link to={`/about/`}>もっと詳しく</Link></div>
                    </div>
                </section>
                <section className="topContact">
                    <div className="inner">
                        <h2>資金調達でお困りの方へ</h2>
                        <ul>
                            <li>起業したい</li>
                            <li>設備投資したい</li>
                            <li>補助金に興味がある</li>
                            <li>Ｍ＆Ａの資金が必要</li>
                        </ul>
                        <p>
                            オンラインミーティングのご予約は<br />お電話・メールから
                        </p>
                        <div className="topContactbutton"><Link to={`/contact/`}>今すぐお問い合わせ</Link></div>
                    </div>
                </section>
          <Relatedjirei id={pageContext.id} />
          <Relatedsogyomae id={pageContext.id} />
          <Relatedshikin id={pageContext.id} />
          <FooterContainer />
        </div>
        </div>
        <Sidebar />
    </main>
  </Layout>
)

export const query = graphql`
query {
    allContentfulBlogPost(
      sort: {fields: createdAt, order: DESC}
      skip: 0
      limit: 4
    ) {
        edges {
          node {
            id
            title
            slug
            publishDate
            publishDateJP: publishDate(formatString: "YYYY/MM/DD")
            category {
              category
              categorySlug
              id
            }
            eyecatch {
              fluid(maxWidth: 1600) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
          }
        }
      }
      banner: file(relativePath: {eq: "trialbanner.png"}) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
}
`
